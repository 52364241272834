@import 'apps/client/src/scss/variables';

@mixin pseudo($loc: before, $content: '', $position: absolute) {
  position: relative;

  &::#{$loc} {
    content: $content;
    position: $position;
  }
}

@mixin hover-opacity() {
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

@mixin center-vertically-absolute() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin custom-ams-button($color) {
  border: 1px solid $color;
  font-size: min(13px, 0.8rem);
  color: $color;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  @include hover-opacity();

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
