@import 'intl-tel-input/build/css/intlTelInput';
@import 'bootstrap/scss/bootstrap.scss';
@import 'apps/client/src/scss/variables';
@import 'apps/client/src/scss/form';
@import 'apps/client/src/scss/typography';
@import 'apps/client/src/scss/buttons';
@import 'apps/client/src/scss/modal';
@import 'apps/client/src/scss/text-toggle';
@import 'apps/client/src/scss/toast';
@import 'apps/client/src/scss/cookies-declaration';

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  font-size: 12px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }

  @include media-breakpoint-up(md) {
    font-size: 17px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  * {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-red;
      outline: 1px solid $color_gray;
    }
  }
}

.container {
  @include media-breakpoint-up(xl) {
    max-width: $xl-container-width;
  }
}

.grecaptcha-badge {
  z-index: 123;
}
