@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueBook.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Uni Neue', sans-serif;
}

//.f-lato {
//  font-family: 'Lato', sans-serif;
//}

.f-uni {
  font-family: 'Uni Neue', sans-serif;
}

.ams-title {
  font-weight: 900;
  text-align: center;
  position: relative;
  display: inline-block;
  @extend .p-28;
  @extend .c-red;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color_red;
    bottom: -8px;
  }
}

.p-58 {
  font-size: 2.9rem;
}

.p-55 {
  font-size: 2.75rem;
}

.p-45 {
  font-size: 2.25rem;
}

.p-40 {
  font-size: 2rem;
}

.p-35 {
  font-size: 1.75rem;
}

.p-30 {
  font-size: 1.5rem;
}

.p-28 {
  font-size: 1.4rem;
}

.p-25 {
  font-size: 1.25rem;
}

.p-24 {
  font-size: 1.2rem;
}

.p-23 {
  font-size: 1.15rem;
}

.p-22 {
  font-size: 1.1rem;
}

.p-20 {
  font-size: 1rem;
}

.p-18 {
  font-size: 0.9rem;
}

.p-16 {
  font-size: 0.8rem;
}

.p-15 {
  font-size: 0.75rem;
}

.p-14 {
  font-size: max(12px, 0.7rem);
}

.p-13 {
  font-size: max(12px, 0.65rem);
}

.p-12 {
  font-size: max(11px, 0.6rem);
}

.p-11 {
  font-size: max(10px, 0.55rem);
}

.p-10 {
  font-size: max(10px, 0.5rem);
}

.p-9 {
  font-size: max(9px, 0.45rem);
}

.p-7 {
  font-size: max(7px, 0.3rem);
}

.c-white {
  color: #fff;
}

.c-black {
  color: #000;
}

.c-gray {
  color: $color-gray;
}

.c-input-placeholder {
  color: $color_input-placeholder;
}

.c-blue {
  color: $color_blue;
}

.c-blue-2 {
  color: $color_blue_2;
}

.c-red {
  color: $color_red;
}

.l-h-40 {
  line-height: 40px;
}

.l-h-30 {
  line-height: 30px;
}

.l-h-29 {
  line-height: 29px;
}

.l-h-25 {
  line-height: 25px;
}

.l-h-22 {
  line-height: 22px;
}

.l-h-20 {
  line-height: 20px;
}

.l-h-19 {
  line-height: 19px;
}

.l-h-18 {
  line-height: 18px;
}

.l-h-16 {
  line-height: 16px;
}

.l-h-14 {
  line-height: 14px;
}

.l-h-7 {
  line-height: 7px;
}

.f-w-900 {
  font-weight: 900;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-200 {
  font-weight: 200;
}
