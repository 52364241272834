@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.ngx-toastr {
  font-size: 0.8rem;
}

.toast-container {
  z-index: 12345;
  position: fixed;

  @include media-breakpoint-up(sm) {
    top: 120px;
  }

  .toast-success {
    background-color: $color_green-amica;
  }

  .toast-error {
    background-color: $color-red_2;
  }
}
