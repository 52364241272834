@import 'variables';
@import 'mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.ams-form {
  .vmc-error {
    color: $color_red;
    font-size: 12px;
    margin-bottom: 0;
    padding: 0 21px;
  }

  label {
    font-size: 13px;

    a {
      font-size: inherit;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    height: 45px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid $color_input-border;
    outline: none;
    width: 100%;
    letter-spacing: 0;
    padding: 4px 21px;
    color: $color_gray;
    font-size: max(12px, 0.65rem);
    line-height: 12px;

    &:-moz-placeholder,
    &:-ms-input-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: rgba(87, 86, 86, 0.6);
    }

    &.ng-invalid.ng-touched {
      border: 1px solid $color_red !important;

      &:focus,
      &.used {
        &.ng-invalid.ng-touched ~ label {
          color: $color_red;
        }
      }
    }

    &:disabled {
      color: $color_input-placeholder;
    }

    &:focus {
      border: 1px solid $color_gray;
    }
  }

  .group {
    position: relative;
    margin: 24px 0 8px;

    .toggle-password {
      transform: translateY(-50%);
      position: absolute;
      top: 48%;
      right: 10px;
      z-index: 1233;
      cursor: pointer;
    }

    .success-icon,
    .error-icon {
      transform: translateY(-50%);
      text-align: center;
      font-weight: bold;
      width: 18px;
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 1233;
      pointer-events: none;
      font-size: 12px;
      height: 18px;
      border-radius: 50%;

      &.ams-password {
        right: 32px;
      }
    }

    .success-icon {
      color: $color_green;
      border: 2px solid $color_green;
      line-height: 1;
    }

    .error-icon {
      border: 2px solid $color-red;
      color: $color-red;
      line-height: 14px;
    }
  }

  ngx-intl-tel-input ~ label,
  input[type='text'] ~ label,
  input[type='password'] ~ label,
  input[type='email'] ~ label {
    position: absolute;
    transition: all 0.2s ease;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 100;
    color: $color_input-placeholder;
    background: #fff;
    display: inline-block;
    padding: 0 4px;
  }

  input[type='text'] ~ label,
  input[type='password'] ~ label,
  input[type='email'] ~ label {
    left: 21px;
  }

  ngx-intl-tel-input ~ label {
    left: 45px;
  }

  ngx-intl-tel-input.used ~ label,
  ngx-intl-tel-input:focus-within ~ label,
  input:focus[type='text'] ~ label,
  input.used[type='text'] ~ label,
  input.h-used[type='text'] ~ label,
  input:focus[type='password'] ~ label,
  input.used[type='password'] ~ label,
  input:focus[type='email'] ~ label,
  input.used[type='email'] ~ label {
    font-size: 12px;
    left: 21px;
    top: 0;
  }

  label.radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0 42px 6px 0;
    cursor: pointer;
    position: relative;
    color: $color_gray;
    font-size: 14px;
    line-height: 25px;

    .design {
      border-radius: 50%;
      margin-right: 12px;
      position: relative;
      width: 30px;
      height: 30px;
      background: #fff;
      border: 2px solid #dbd7d7;

      &::before {
        content: '';
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 0.2s;
        width: 10px;
        height: 10px;
        background: #707070;
      }
    }

    input[type='radio'] {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      &:checked {
        & + .design {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  input[type='checkbox'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;

      &.ng-invalid.ng-touched + label {
        &:before {
          border: 1px solid $color_red;
        }
      }

      & + label {
        position: relative;
        padding-left: 42px;
        cursor: pointer;
        font-size: 12px;
        line-height: 15px;
        font-weight: normal;
        color: $color_gray;
        margin-bottom: 10px;

        &:before,
        &:after {
          content: '';
          position: absolute;
          transition: all 0.2s;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          left: 0;
          width: 25px;
          height: 25px;
          border-radius: 4px;
          background: transparent;
          border: 1px solid $color_check-border;
        }

        &:after {
          background-image: url('../assets/images/checkmark.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
          left: 0;
          display: block;
          width: 25px;
          height: 25px;
          z-index: 1;
        }
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &:disabled:checked + label:after {
      color: #999;
    }

    &:disabled + label {
      color: #aaa;
      cursor: not-allowed;

      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }

    &:checked:focus,
    &:not(:checked):focus {
      & + label:before {
        border: 1px solid $color_gray;
      }
    }
  }

  ngx-intl-tel-input {
    &.ng-invalid.ng-touched {
      .ams-ngx-intl-tel-input {
        border: 1px solid $color_red;
      }
    }

    .ams-ngx-intl-tel-input {
      height: 45px;
      border-radius: 4px;
      background-color: transparent;
      border: 1px solid $color_input-border;
      outline: none;
      width: 100%;
      letter-spacing: 0;
      padding: 4px 21px 4px 52px;
      color: $color_gray;
      font-size: max(12px, 0.65rem);
      line-height: 12px;

      &:-moz-placeholder,
      &:-ms-input-placeholder,
      &::-moz-placeholder,
      &::-webkit-input-placeholder {
        color: rgba(87, 86, 86, 0.6);
      }

      &:disabled {
        color: $color_input-placeholder;
      }

      &:focus {
        border: 1px solid #000;
        box-shadow: none;
      }
    }

    div.iti {
      width: 100%;
    }

    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background: #fff;
    }

    .dropdown-menu {
      font-size: 13px;

      .search-container {
        font-size: 13px;

        input#country-search-box {
          outline: none;
          padding: 4px 21px;
          height: 41px;
        }
      }
    }
  }

  asl-google-signin-button#ams-google-btn {
    & > div {
      width: 100%;
      height: 42.5px;

      & > div:first-child {
        width: 100%;
        text-align: center;
        display: inline-block;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
        text-decoration: none;
        padding: 10px 14px;
        transition: all 0.2s ease-in-out;
        height: 100%;
        background-color: #fff;
        border-radius: 3px;
        position: relative;
        cursor: pointer;

        & > div {
          position: absolute;
          background: transparent;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 123;
          width: 100%;
          height: 100%;
          border: none;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-image: url('../assets/images/google.svg');
          width: 66px;
          height: 22px;
          z-index: 1;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 3px 2px $color_red_2;
        }
      }

      iframe {
        display: none !important;
        //height: 100% !important;
        //width: 100% !important;
        //position: absolute !important;
        //z-index: 123 !important;
      }
    }
  }
}
