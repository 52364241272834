@import 'variables';
@import 'mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.ams-text-toggle {
  padding-right: 55px;
  position: relative;
  overflow: hidden;

  &__hidden {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__btn {
    position: absolute;
    right: 1rem;
    z-index: 1;
    top: 0;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 17px;
    text-align: left;
    color: #454545;
    cursor: pointer;
    @include hover-opacity();

    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      width: 100%;
      bottom: -10px;
      height: 1.5px;
      background: $color-red;
    }
  }
}
