@use 'mixins' as m;
@use 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.without-border-radius {
  .modal-content {
    border-radius: 0;
  }
}

.acd-modal-content {
  background-color: #fff;
  padding: 43px 37px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);

  &__btns {
    display: flex;
    gap: 13px;

    button {
      border-radius: 9px;

      &:last-child {
        padding-left: 30px;
        padding-right: 43px;

        &:after {
          right: 18px;
        }
      }
    }
  }

  button.close-btn {
    color: #dbd7d7;
    background-color: transparent;
    font-size: 31px;
    line-height: 1;
    width: 36px;
    border-radius: 50%;
    outline: none;
    padding-bottom: 6px;
    position: absolute;
    right: 19px;
    top: 19px;
    @include m.hover-opacity();
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.acd-override-modal {
  .modal-dialog {
    width: 467px;
    max-width: 100%;
  }
}
