@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

button,
a {
  border: none;

  &.ams-btn {
    text-align: center;
    display: inline-block;
    font-size: 15px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    border-radius: 5px;
    padding: 10px 14px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: 0 0 3px 2px $color_red_2;
    }

    &:disabled {
      opacity: 0.5;
    }

    &__white {
      background: #fff;
      color: $color_red;

      &:not(:disabled):hover {
        color: #fff;
        background: $color_red_2;
      }
    }

    &__red {
      background: $color_red_2;
      color: #fff;

      &:not(:disabled):hover {
        color: $color_red_2;
        background: #fff;
      }
    }
  }
}
