$xl-container-width: 1088px;
$height-header: 108px;

$color_red: #e41629;
$color_red_2: #e41429;
$color_blue: #18a3da;
$color_blue_2: #0b7fff;
$color_gray: #333;
$color_green: #86c630;
$color_green-amica: #46963b;

$color_input-border: #e4e4e4;
$color_check-border: #c4c4c4;
$color_input-placeholder: #8b8b8b;
